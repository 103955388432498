import React from 'react';
// --- UI
import { WelcomePage, SignInPage, SignUpPage, DeveloperPage, DocumentPage } from './pages';
// --- JS
import { routes } from './shared/routes';
import { useAppDispatch, useAppSelector } from './store';
import { fetchInitialized } from './store/App/App.actions';
import { Routes, Route, Navigate } from 'react-router-dom';
import { selectInitilized } from './store/App/App.selectors';


const App: React.FC = () => {
	const dispatch = useAppDispatch();
	const initialized = useAppSelector(selectInitilized);

	React.useEffect(() => {
		console.log(`(branch: main) => developer.asend.ai`);
		dispatch(fetchInitialized());
	}, []);

	// TO-DO: Loading Component
	if (!initialized) {
		return <div />;
	}

	return (
		<React.Fragment>
			<Routes>
				<Route path={routes.signIn} element={<SignInPage />} />
				<Route path={routes.signUp} element={<SignUpPage />} />
				<Route path={routes.welcome} element={<WelcomePage />} />
				<Route path={routes.developerPortal} element={<DeveloperPage />} />
				<Route path={routes.documentation} element={<DocumentPage />}/>
				<Route path='*' element={<Navigate to={routes.welcome} />} />
			</Routes>
		</React.Fragment>
	);
};

export default App;
